import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Heading } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
} from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';

import WithAnimation from '@components/Common/WithAnimation';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%" padding="16px">
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="xl"
            textAlign="center"
            margin="0"
            color="mainColorText"
            fontFamily="CustomFont"
            marginBottom="8px"
          >
            Live Streaming
          </Heading>
        </WithAnimation>
        {false && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              marginBottom="16px"
              dangerouslySetInnerHTML={{
                __html: `${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {/* Live stream section */}
        <WithAnimation>
          <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
            <iframe
              title={`Live wedding of ${THE_BRIDE}`}
              src={YOUTUBE_EMBED}
              allowFullScreen
              loading="lazy"
              style={{ borderRadius: '8px' }}
            />
          </AspectRatio>
        </WithAnimation>
        <WithAnimation>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <Center>
          <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
            <Button
              size="xs"
              fontWeight="normal"
              bgColor="bgAlternative"
              marginBottom="16px"
              {...BUTTON_PROPS}
              {...BUTTON_PADDING_PROPS}
            >
              {txt.open[lang]}
            </Button>
          </Link>
        </Center>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
